import { useState, useRef } from "react";
import React, { useEffect } from 'react';
import Hilogo from './Name.png';
import chatdesign from './design.png';
import msgicon from "./msg-icon.png";
import sendicon from "./send-icon.svg";
import favicon from "./hi-image.png";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faHouse, faXmark, faArrowLeft, faCircle} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import Parser from 'html-react-parser';


function App() {
  const [chatblock, setShow] = useState(false);
  const [messageblock, setMessageBlock] = useState(false);
  const [messageviewblock, setMessageViewBlock] = useState(false);
  const [queryinput, setQueryInput] = useState('');
  const [querySummaryinput, setQuerySummaryInput] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setselectedFileName] = useState(null);
  const [chatlogo, setChatLogo] = useState(true);
  const [appclosebtn, setAppCloseBtn] = useState(false);
  const [base64String, setBase64String] = useState('');

  const [apiData, setApiData] = useState('');
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setselectedFileName(event.target.files[0].name);
    const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result.split(',')[1];
        setBase64String(base64);
      };
      reader.readAsDataURL(event.target.files[0]);
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setQueryInput(event.target.value);
      handleSendQuery(event, queryinput);
    }
  };

  const handleInputChange = (event) => {
    setQueryInput(event.target.value);
  };

  const handleShowChat = (e) => {
   // e.preventDefault();
    setMessageBlock(false);
    setShow(true);
    setMessageViewBlock(false);
    setMessageBlock(false);
    setChatLogo(false);
    setAppCloseBtn(true);
  }

  /*const handleShowMessageBlock = (e) => {
    // e.preventDefault();
    setShow(false);
    setMessageViewBlock(false);
    setMessageBlock(true);
    
   }*/

   const handleShowChatView = (e) => {
    // e.preventDefault();
    setShow(false);
    setMessageBlock(false);
    setMessageViewBlock(true);
    const targetDiv2 = document.getElementById('chat-list-messages');
    targetDiv2.scrollTo(0, targetDiv2.scrollHeight);
   }

   const handleCloseMessages = (e) => {
    // e.preventDefault();
    setShow(false);
    setMessageBlock(false);
    setMessageViewBlock(false);
    setAppCloseBtn(false);
    setChatLogo(true);
    
   }

  const handleSendQuery = (e, query_text) => {

    if(query_text !='')
    { 
      setQueryInput('');
      const today = Date.now();
      const todate_time = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today)
      const targetDiv1 = document.getElementById('chat-list-messages').innerHTML;
      const renderdata = Parser(targetDiv1 + '<div className="user-message" id="msg'+today+'"> <div className="message-profile"><img src='+favicon+' className="chat-user-profile" alt="profile-logo" /></div> <div className="message-user-text"> <div className="msg-text">'+query_text+'</div> <div className="message-time">'+todate_time+'</div> </div></div>  <div className="system-message" id="'+today+'"> Please Wait...</div>');
      const targetDiv = document.getElementById('chat-list-messages');
      const result_today1 = document.getElementById(today);
      const App = () => {
        return renderdata;
      };

      ReactDOM.hydrate(<App />, targetDiv, () => {
        console.log('React component is mounted and interactive now!');
        targetDiv.scrollTo(0, targetDiv.scrollHeight);
      });
      //createRoot(document.getElementById("chat-list-messages")).render( Parser(targetDiv1))
      handleShowChatView(); 
     /* const targetDiv_rest = document.getElementById('chat-list-messages');
      const result_today = document.getElementById(today);
      targetDiv_rest.scrollTo(0, result_today.scrollHeight);*/

      const sendquery = {
        query: query_text,
      }
      axios.post('https://chatbox.signulu.com/api/signulubot', JSON.stringify(sendquery) ,{ 
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        console.log(response.data);
        const query_answer = response.data;
        const result_content =query_answer;
        //ReactDOM.unmountComponentAtNode(document.getElementById("wait_query"));
        const targetDiv2 = document.getElementById('chat-list-messages');
        const innertext = Parser(result_content);
        const result_today = document.getElementById(today);
        const App = () => {
          return innertext;
        };

        ReactDOM.hydrate(<App />, result_today, () => {
          console.log('React component is mounted and interactive now!');
          targetDiv.scrollTo(0, targetDiv.scrollHeight);
        });
      })
      .catch(function (error) {
        console.log(error);
        const result_content = 'Error, try again after 30 sec';
        const targetDiv2 = document.getElementById('chat-list-messages');
        const innertext = Parser(result_content);

        const App = () => {
          return innertext;
        };
        const result_today = document.getElementById(today);
        ReactDOM.hydrate(<App />, result_today, () => {
          console.log('React component is mounted and interactive now!');
          targetDiv.scrollTo(0, targetDiv.scrollHeight);
        });
        setQueryInput('');
      });
   }else{
    queryinput.focus();
   }
  }
  const handleUpload = () => {
    // Handle file upload logic (e.g., send the file to the server)
    if (selectedFile) {
      document.getElementById('summarybtn').innerHTML = "Please Wait...";
      // Implement file upload logic here (e.g., using Axios or Fetch)
      const sendquery1 = {
        querySummaryinput: querySummaryinput,
        encoded_pdf: base64String
      }
      axios.post('https://chatbox.signulu.com/api/summarizedoc', JSON.stringify(sendquery1) ,{ 
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        console.log(response.data);
        document.getElementById('summarybtn').innerHTML = "Upload";
        const innertext = Parser(response.data);
        document.getElementById('summary_result').innerHTML = innertext;
      })
      .catch(function (error) {
        console.log(error);
        
      });
      console.log('Uploading file:', selectedFile);
    } else {
      console.log('No file selected.');
    }
  };
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ textAlign: "left", margin: "20px", width: "25%"}}>
          <div><input type="file" onChange={handleFileChange} style={{ border: "1px solid #1c324f", padding: "6px", color: "black", width: "100%" }} /></div>
          <div><textarea name="postContent" rows="5" placeholder="Enter your Query" style={{ border: "1px solid #1c324f", padding: "6px", color: "black", width: "100%", marginTop: "12px", marginBottom: "12px"}} value={querySummaryinput} onChange={(e) => setQuerySummaryInput(e.target.value)} /></div>
          <div><button className="submitbtn" onClick={handleUpload} id="summarybtn">Upload</button></div>
          
        </div>
        <div  style={{color: "black", fontSize: "15px", textAlign: "left", margin: "20px" }}>
          <pre id="summary_result">
            
          </pre>
        </div>
        {/* Message block */}
        <div className="messageviewblock" id="messageviewblock" style={{ display: messageviewblock ? "block" : "none" }}>
            <div className="message-block-header">
                <div className="profile-block">
                  <div className="back-icon" onClick={handleShowChat}>
                      <FontAwesomeIcon icon={faArrowLeft}  /> 
                    </div>
                  <div className="profile-image-block">
                      <img src={favicon} className="profile-logo" alt="profile-logo"  /> 
                      <div className="userinfo-block">
                         <div className="username">Signulu</div>
                         <div className="userstatus"> <span><FontAwesomeIcon icon={faCircle} className="user-state-icon" /></span> online</div> 
                      </div>
                  </div>
                  <div className="close-icon-block"><FontAwesomeIcon icon={faXmark} className="chat-close" onClick={handleCloseMessages} /></div>
                </div>              
            </div>
            <div className="chat-list-messages" id="chat-list-messages">

            </div>
            <div className="chat-bottom-menu">
                <div className="msg-input-block"><input type="text" className="text-input" id="queryinput" name="queryinput" placeholder="Ask your question" value={queryinput} onChange={handleInputChange} onKeyDown={handleKeyPress} /> <button type="button" className="send-button" onClick={(e)=> handleSendQuery(e, queryinput)}><img src={sendicon}  alt="send-icon" className="send-button-img" /></button></div>
                <div className="chat-icon-block">
                  
                  <span className="powered-by">Powered by ChatGPT</span>
                </div>
            </div>
        </div>
        {/* Message block */}
        <div className="messageblock" id="messageblock" style={{ display: messageblock ? "block" : "none" }}>
            <div className="message-block-header">
               <div className="message-text">Messages <FontAwesomeIcon icon={faXmark} className="chat-close cursor" onClick={handleCloseMessages} /></div>              
            </div>
            <div>
              <img src={chatdesign} className="chat-design" alt="chatdesign"  />
            </div>
            <div className="list-messages">
                <div className="message-view">
                    <div className="message-logo"><img src={msgicon} className="message-font-icon" /></div>
                    <div className="query-info" onClick={(e)=> handleSendQuery(e, 'How to create a document?')}>
                      <div className="query-text">How to create a document?</div>
                      <div className="query-date">12 min ago</div>
                    </div>
                </div>
                <div className="message-view">
                    <div className="message-logo"><img src={msgicon} className="message-font-icon" /></div>
                    <div className="query-info" onClick={(e)=> handleSendQuery(e, 'How to create a document?')}>
                      <div className="query-text">How to create a document?</div>
                      <div className="query-date">12 min ago</div>
                    </div>
                </div>
                <div className="message-view">
                    <div className="message-logo"><img src={msgicon} className="message-font-icon" /></div>
                    <div className="query-info" onClick={(e)=> handleSendQuery(e, 'How to create a document?')}>
                      <div className="query-text">How to create a document?</div>
                      <div className="query-date">12 min ago</div>
                    </div>
                </div>
            </div>
            <div className="bottom-menu">
                <div><a href="javascript:;" onClick={handleShowChat}><FontAwesomeIcon icon={faHouse}  /> Home</a></div>
                <div><a href="javascript:;" onClick={handleShowChatView} className="option-active"><FontAwesomeIcon icon={faMessage}   /> Ask</a></div>
            </div>
        </div>
        {/* Home block */}
        <div className='chatblock' id="chatblock" style={{ display: chatblock ? "block" : "none" }}>
            <div className='chat-view'>
                <div>
                  <img src={favicon} className="hi-logo" alt="Hi Logo" />
                  <div className='name-font'>Hello <span>Signulu</span>.</div>
                  <div className='name-font'>How can we help?</div>
                </div>
            </div>
            <div className='chat-q'>
                <div className='chat-q-list'>
                    <div className='chat-header'>Some of the frequently asked Questions:</div>
                    <ul className='faqs-list'>
                        <li className='faqs' onClick={(e)=> handleSendQuery(e, 'How to create a document?')}>How to create a document?</li>
                        <li className='faqs' onClick={(e)=> handleSendQuery(e, 'How to add signature?')}>How to add signature?</li>
                        <li className='faqs' onClick={(e)=> handleSendQuery(e, 'How to create a folder?')}>How to create a folder?</li>
                    </ul>
                </div>
            </div>
            <div className='chat-options-list'>
                  <div className='option-div'><a href="javascript:;" className="option-active" onClick={handleShowChat}><FontAwesomeIcon icon={faHouse} /> Home</a></div> <div className='option-div'><a href="javascript:;" onClick={handleShowChatView}><FontAwesomeIcon icon={faMessage} /> Ask</a></div>
            </div>
        </div>
        <div>
            <div><FontAwesomeIcon icon={faMessage} className="chat-logo" id="chatlogo" onClick={handleShowChat} style={{ display: chatlogo ? "block" : "none" }} /></div>
            
            <FontAwesomeIcon icon={faXmark} className="app-close-btn" id="appclosebtn" onClick={handleCloseMessages} style={{ display: appclosebtn ? "block" : "none" }} />
        </div>         
      </header>
    </div>
  );
}

export default App;
